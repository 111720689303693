<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="결재요청 정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- <c-btn
                  v-if="editable"
                  label="결재요청"
                  icon="check"
                  color="purple"
                  @btnClicked="saveApprRequest" /> -->
              </q-btn-group>
            </template>
            <template slot="card-detail">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <div class="col-12">
                    <c-text
                      :editable="false"
                      label="결재 업무명"
                      name="approvalTypeName"
                      v-model="apprtype.approvalTypeName">
                    </c-text>
                  </div>
                  <div class="col-12">
                    <c-text
                      :editable="editable"
                      :required="true"
                      label="결재 요청명"
                      name="approvalRequestName"
                      v-model="reqdata.approvalRequestName">
                    </c-text>
                  </div>
                </div>
                <div class="col-12" style="margin-top:-15px !important;">
                  <div class="appr-line-detail-layer2">
                    <c-card title="결재선" class="cardClassDetailFormLine" topClass="topcolor-orange">
                      <template slot="card-button">
                        <q-btn-group outline >
                          <c-btn label="결재선 변경" icon="change_circle" color="orange" @btnClicked="changeApprLine" />
                        </q-btn-group>
                      </template>
                      <template slot="card-detail">
                        <div class="col-12">
                          <draggable
                            tag="ul"
                            :list="gridline.data"
                            class="list-group"
                            handle=".handle"
                            v-bind="dragOptions"
                          >
                            <transition-group type="transition" name="flip-list">
                              <li
                                class="list-group-item"
                                v-for="(element, idx) in gridline.data"
                                :key="element.approvalUserId + idx + element.approvalKindCd"
                              >
                                <i class="material-icons linehandlex no-move">reorder</i>
                                <div class="linetext linespan1" :class="getLineKindColor(element.approvalKindCd)">{{ element.approvalKindName }} </div>
                                <div class="linetext linespan2">{{ element.deptName }}</div>
                                <div class="linetext linespan3">{{ element.approvalUserName }}</div>
                              </li>
                            </transition-group>
                          </draggable>
                        </div>
                      </template>
                    </c-card>
                    <c-card title="회람" class="cardClassDetailFormLine" topClass="topcolor-orange">
                      <template slot="card-detail">
                        <div class="col-12">
                          <draggable
                            tag="ul"
                            :list="gridline2.data"
                            class="list-group"
                            handle=".handle"
                            v-bind="dragOptions"
                          >
                            <transition-group type="transition" name="flip-list">
                              <li
                                class="list-group-item"
                                v-for="(element, idx) in gridline2.data"
                                :key="element.approvalUserId + idx + element.approvalKindCd"
                              >
                                <i class="material-icons linehandlex no-move">reorder</i>
                                <div class="linetext linespan1" :class="getLineKindColor(element.approvalKindCd)">{{ element.approvalKindName }} </div>
                                <div class="linetext linespan2">{{ element.deptName }}</div>
                                <div class="linetext linespan3">{{ element.approvalUserName }}</div>
                              </li>
                            </transition-group>
                          </draggable>
                        </div>
                      </template>
                    </c-card>
                  </div>
                </div>
            </template>
          </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="결재 상세내용" class="cardClassDetailForm displayBlockCard" bgClass="purple" titleClass="white">
            <template slot="card-detail">
              <component :is="component" :popupParam.sync="popupParam.approvalParamValue" />
            </template>
          </c-card>
      </div>
    </div>
    <q-dialog v-model="dialogShow" persistent>
      <q-card style="min-width: 350px">
        <q-form ref="editForm">
          <c-card title="서명란" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn 
                  v-show="editable" 
                  label="결재요청" 
                  icon="save" 
                  @btnClicked="saveDialog"/>
                <c-btn label="지우기" color="red" :showLoading="false" @btnClicked="eraseSignature" />
                <c-btn 
                  label="취소" 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="row">
                <div class="col-xs-12 col-sm-12">
                  <VueSignaturePad width="300px" height="300px" ref="signaturePad" />
                </div>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn
            :flat="true"
            v-if="editable"
            label="결재요청"
            icon="check"
            color="purple"
            @btnClicked="saveApprRequest" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import VueSignaturePad from 'vue-signature-pad';
import Vue from 'vue';
import draggable from 'vuedraggable';
let signaturePad = null;
Vue.use(VueSignaturePad)
export default {
  name: 'appr-request',
  components: {
      draggable,
  },
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sysApprovalRequestId: '',
        approvalTypeCd: '', // 결재 유형 코드
        approvalRequestName: '', // 결재 요청명
        approvalParamValue: '', // 결재 파라미터
        approvalConnId: '',  // 결재업무 연결용 업무일련번호
      }),
    },
  },
  data() {
    return {
      maxLineGrp: 1,
      dialogShow: false,
      editable: true,
      component: null, // 콤포넌트
      detailTypeUrl: '',
      apprLineUrl: '',
      infoUrl: '',
      listUrl: '',
      saveApprRequestUrl: '',
      electronSignature: '',
      apprtype: {
        approvalTypeCd: '',  // 결재유형코드
        approvalTypeName: '',  // 결재업무명
        approvalUrl: '',  // 결재화면 page경로
        approvalParam: '',  // 결재파라메터
        approvalContent: '',  // 결재메일내용
      },
      reqdata: {
        approvalTypeCd: '',  // 결재유형코드
        approvalRequestName: '',  // 결재 요청명
        approvalStatusCd: '',  // 결재상태
        approvalParam: '',  // 결재파라메터
      },
      columnsline: [
        {
          name: 'approvalKindName',
          field: 'approvalKindName',
          label: '구분',
          align: 'center',
          style: 'width: 80px',
          type: 'custom',
          colClass: 6,
          sortable: false,
        },
        {
          name: 'deptName',
          field: 'deptName',
          label: '부서',
          align: 'center',
          style: 'width: 120px',
          colClass: 6,
          sortable: false,
        },
        {
          name: 'approvalUserName',
          field: 'approvalUserName',
          label: '성명',
          align: 'center',
          style: 'width: 80px',
          colClass: 6,
          sortable: false,
        },
        {
          name: 'approvalStatusName',
          field: 'approvalStatusName',
          label: '결재상태',
          align: 'center',
          style: 'width: 80px',
          type: 'custom',
          colClass: 6,
          sortable: false,
        },
        {
          name: 'returnReason',
          field: 'returnReason',
          label: '반려사유',
          align: 'left',
          type: 'textarea',
          sortable: false,
          colClass: 6,
          disableTarget: 'approvalStatusCd',
          disableCon: 'ASC0000001',
        },
        {
          name: 'approvalDt',
          field: 'approvalDt',
          label: '결재일시',
          align: 'center',
          colClass: 6,
          style: 'width: 160px',
          sortable: false,
        },
      ],
      gridline: {
        columns: [
          {
            name: 'approvalKindName',
            field: 'approvalKindName',
            label: '',
            align: 'center',
            type: 'custom',
            colClass: 4,
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '',
            align: 'center',
            style: 'width: 50%',
            colClass: 4,
            sortable: false,
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            label: '',
            align: 'center',
            colClass: 4,
            style: 'width: 30%',
            sortable: false,
          },
        ],
        data: [],
      },
      gridline2: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width: 60%',
            sortable: false,
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            label: '성명',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    init() {
      this.infoUrl = selectConfig.sys.appr.info.get.url;
      this.listUrl = selectConfig.sys.appr.line.list.url;
      this.detailTypeUrl = selectConfig.sys.appr.type.get.url;
      this.apprLineUrl = selectConfig.sys.appr.line.last.url;
      this.saveApprRequestUrl = transactionConfig.sys.appr.request.insert.url;
      this.reqdata.approvalRequestName = this.popupParam.approvalRequestName;
      if (this.popupParam.sysApprovalRequestId) { // 재상신의 경우
        this.gridline.columns = this.columnsline;
        this.getApprInfo();
        this.getApprLines();
      } else { // 첫 결재요청
        this.getApprType();
        this.getApprLine();
        this.getApprLine2();
      }
    },
    getApprLines() {
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      this.$http.url = this.listUrl;
      this.$http.param = {
        sysApprovalRequestId: this.popupParam.sysApprovalRequestId,
      };
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          let lineData0 = _result.data.filter( x => {
            return x.approvalKindCd != 'AKC0000009';
          });
          let lineData1 = _result.data.filter( x => {
            return (x.approvalKindCd == 'AKC0000001' || x.approvalKindCd == 'AKC0000002' || x.approvalKindCd == 'AKC0000003' || x.approvalKindCd == 'AKC0000004' || x.approvalKindCd == 'AKC0000010') && x.approvalStatusCd != ''
          });
          let lineData2 = _result.data.filter( x => {
            return x.approvalKindCd == 'AKC0000009'
          });
          let maxgrps = [];
          this.$_.forEach(lineData0, _item => {
            maxgrps.push(_item.approvalLineGrp)
          });
          var _max = 0;
          if (maxgrps.length > 0) {
            _max = Math.max.apply(null, maxgrps);
          }
          this.maxLineGrp = _max + 1;

          this.gridline.data = lineData1;
          let cnt = parseInt(this.gridline.data[this.gridline.data.length-1].approvalLineNo);
          this.$_.forEach(lineData0, _item => {
            if (_item.approvalLineGrp == _max) {
              cnt++;
              this.gridline.data.push({
                approvalKindCd: _item.approvalKindCd == 'AKC0000001' ? 'AKC0000010' : _item.approvalKindCd, // 상신
                approvalKindName: _item.approvalKindName == '상신' ? '재상신' : _item.approvalKindName,
                approvalUserId: _item.approvalUserId,
                approvalUserName: _item.approvalUserName,
                deptName: _item.deptName,
                approvalLineNo: cnt,
                approvalStatusCd: '',
                approvalStatusName: '',
                approvalLineGrp: this.maxLineGrp,
                approvalDt: '',
                draggable: _item.approvalKindCd == 'AKC0000001' ? false : true,
              });
            }
          })
          this.gridline2.data = lineData2;
        }
      },
      () => {
      });
    },
    getApprInfo() {
      this.$http.url = this.infoUrl;
      this.$http.param = {
        sysApprovalRequestId: this.popupParam.sysApprovalRequestId
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.apprtype.approvalTypeName = _result.data.approvalTypeName;
        this.reqdata.approvalRequestName = _result.data.approvalRequestName;
        this.component = () => import(`@/pages${_result.data.approvalUrl}`);
        this.popupParam.approvalParamValue = JSON.parse(_result.data.approvalParam);
        this.$_.extend(this.popupParam.approvalParamValue, {approvalDocType: 'TODO'});
      },
      () => {
      });
    },
    getApprType() {
      this.$http.url = this.$format(this.detailTypeUrl, this.popupParam.approvalTypeCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.apprtype = _result.data;
        this.component = () => import(`@/pages${this.apprtype.approvalUrl}`);
      },
      () => {
      });
    },
    getApprLine() { // 요청자가 상신자인 최근 결재선호출
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람
      this.$http.url = this.apprLineUrl;
      this.$http.param = {
          approvalTypeCd: this.popupParam.approvalTypeCd,
          approvalUserId: this.$store.getters.user.userId,
          approvalKindCd: 'AKC0000001',
          passFlag: 'N',  // 회람자제외
          passFlagKindCd: 'AKC0000009', // 회람자제외
      };
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          this.gridline.data = _result.data;
          let ordercnt = 1;
          this.$_.forEach(this.gridline.data, _item => {
            _item.approvalLineNo = ordercnt;
            _item.approvalLineGrp = 1;
            ordercnt++;
          });
        } else {
          // 최근이력이 없을 경우 상신자만 추가
          this.gridline.data.push({
            approvalKindCd: 'AKC0000001', // 상신
            approvalKindName: '상신',
            approvalUserId: this.$store.getters.user.userId,
            approvalUserName: this.$store.getters.user.userName,
            deptName: this.$store.getters.user.deptName,
            approvalLineGrp: 1,
            approvalLineNo: '1',
            approvalStatusCd: 'ASC0000000', // 결재요청으로 저장
          });
        }
      },
      () => {
      });
    },
    getApprLine2() { // 요청자가 상신자인 최근 회람자호출
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람
      this.$http.url = this.apprLineUrl;
      this.$http.param = {
          approvalTypeCd: this.popupParam.approvalTypeCd,
          approvalUserId: this.$store.getters.user.userId,
          approvalKindCd: 'AKC0000001',
          passFlag: 'Y',  // 회람자제외
          passFlagKindCd: 'AKC0000009', // 회람자제외
      };
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.gridline2.data = _result.data;
      },
      () => {
      });
    },
    changeApprLine() {
      this.popupOptions.title = '결재선 변경'; // 결재선 변경
      if (this.popupParam.sysApprovalRequestId) { // 재상신의 경우
        let line = this.gridline.data.filter( x => {
          return x.approvalDt == ''
        });
        this.popupOptions.param = {
          apprlinedata: line,
          apprlinedata2: this.gridline2.data,
          approvalLineGrp: this.maxLineGrp,
        };
      }
      else {
        this.popupOptions.param = {
          apprlinedata: this.gridline.data,
          apprlinedata2: this.gridline2.data,
          approvalLineGrp: this.maxLineGrp,
        };
      }
      this.popupOptions.target = () => import(`${'@/pages/common/appr/apprLinePop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeApprLinePopup;
    },
    closeApprLinePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.apprlinedata || data.apprlinedata2) {
        if (this.popupParam.sysApprovalRequestId) { // 재상신의 경우
          let oridata = this.gridline.data.filter( x => {
            return x.approvalDt != ''
          });
          this.gridline.data = oridata.concat(data.apprlinedata);
        } else {
          this.gridline.data = data.apprlinedata;
        }
        this.gridline2.data = data.apprlinedata2;
      }
    },
    getLineKindColor(_cd) {
      let color = '';
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람
      switch(_cd) {
        case 'AKC0000010':
        case 'AKC0000001':
          color = 'blue-grey-4';
          break;
        case 'AKC0000002':
          color = 'green-7';
          break;
        case 'AKC0000003':
          color = 'blue-7';
          break;
        case 'AKC0000004':
          color = 'blue-grey-4';
          break;
      }
      return 'text-' + color;
    },
    getLineKindColor2(_cd) {
      let color = 'font-size-m-up';
      switch(_cd) {
        case '반려':
          color = 'text-weight-bold text-red font-size-m-up';
          break;
      }
      return color;
    },
    // 최근결재 사인이미지 불러오기
    setSignature() {
      signaturePad = this.$refs.signaturePad
      this.$http.url = '/api/sys/appr/signature';
      this.$http.isLoading = false;
      this.$http.param = {
        userId: this.$store.getters.user.userId,
      };
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data) {
          signaturePad.fromDataURL(_result.data);
        }
      })
    },
    saveApprRequest() {
      let chgdata = [];
      if(this.reqdata.approvalRequestName == '') {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '결재 요청명을 입력하세요.', // 결재 요청명을 입력하세요.
          type: 'warning', // success / info / warning / error
        });
      } else if (this.gridline.data.length < 2) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '결재선을 등록하세요.', // 결재선을 등록하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (this.popupParam.sysApprovalRequestId) { // 재상신의 경우
          let ordercnt = 1;
          this.$_.forEach(this.gridline.data, _item => {
            _item.approvalLineNo = ordercnt;
            ordercnt++;
          });
          chgdata = this.gridline.data.filter( x => {
            return x.approvalStatusName == ''
          });
          if (chgdata.length < 2) {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: '재상신 결재선을 등록하세요.', // 재상신 결재선을 등록하세요.
              type: 'warning', // success / info / warning / error
            });
            return false;
          } else {
            chgdata[0].approvalStatusCd = 'ASC0000000';
            chgdata[1].approvalStatusCd = 'ASC0000001';
          }
        }
        // 환경설정 결재서명여부
        if (this.$store.getters.user.approveSignature === 'Y' || this.$store.getters.user.approveSignature === 'M') {
          this.dialogShow = true;
          setTimeout(this.setSignature, 500);
        } else {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '결재를 요청하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let saveData;
              let apprSignature = '';
              signaturePad = this.$refs.signaturePad
              if (signaturePad.saveSignature().isEmpty) {
                apprSignature = ''
              } else {
                apprSignature = signaturePad.saveSignature().data;
              }
              if (this.popupParam.sysApprovalRequestId) { // 재상신의 경우
                this.$_.extend(this.reqdata, {
                  approvalConnId: this.popupParam.approvalConnId, // 결재업무 연결용 업무일련번호
                  approvalTypeCd: this.apprtype.approvalTypeCd,  // 결재유형코드
                  approvalStatusCd: 'ASC0000001',  // 결재상태 (결재중)
                  approvalParam: JSON.stringify(this.popupParam.approvalParamValue),  // 결재상세화면 호출파라메터
                });
                saveData = chgdata.concat(this.gridline2.data);
                this.$http.url = this.saveApprRequestUrl + '/re';
                this.$http.type = 'PUT';
                this.$http.param = {
                  approvalRequestModel: this.reqdata,
                  approvalLineModel: saveData,
                  sysApprovalRequestId: this.popupParam.sysApprovalRequestId,
                  electronSignature: apprSignature,
                };
              } else {
                this.$_.extend(this.reqdata, {
                  approvalConnId: this.popupParam.approvalConnId, // 결재업무 연결용 업무일련번호
                  approvalTypeCd: this.apprtype.approvalTypeCd,  // 결재유형코드
                  approvalStatusCd: 'ASC0000001',  // 결재상태 (결재중)
                  approvalParam: JSON.stringify(this.popupParam.approvalParamValue),  // 결재상세화면 호출파라메터
                });
                let ordercnt = 0;
                // 상신자 제외 첫번째 결재자에 결재중상태로 변경
                this.$_.forEach(this.gridline.data, _item => {
                  if (ordercnt == 1) {
                    _item.approvalStatusCd = 'ASC0000001';
                  }
                  ordercnt++;
                })
                saveData = this.gridline.data.concat(this.gridline2.data);
                this.$http.url = this.saveApprRequestUrl;
                this.$http.type = 'POST';
                this.$http.param = {
                  approvalRequestModel: this.reqdata,
                  approvalLineModel: saveData,
                  electronSignature: apprSignature,
                };
              }
              this.$http.request((_result) => {
                if (_result.data.sysApprovalRequestId !== 'DUPLICATE') {
                  window.getApp.$emit('ALERT', {
                    title: '안내', /* 안내 */
                    message: '결재가 정상적으로 요청되었습니다.', /* 결재가 정상적으로 요청되었습니다. */
                    type: 'success', // success / info / warning / error
                  });
                } else {
                  window.getApp.$emit('ALERT', {
                    title: '안내', /* 안내 */
                    message: '해당 업무는 이미 결재요청되었습니다.\r\n확인바랍니다.\r\n' + '기존결재요청자 : ' + _result.data.approvalExistModel.userName + ' ' + _result.data.approvalExistModel.spotName + '/' + _result.data.approvalExistModel.deptName, /* 해당 업무는 이미 결재요청되었습니다.\n확인바랍니다. */
                    type: 'warning', // success / info / warning / error
                  });
                }
                this.$emit('closePopup');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      }
    },
    saveDialog() {
      let chgdata = [];
      if(this.reqdata.approvalRequestName == '') {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '결재 요청명을 입력하세요.', // 결재 요청명을 입력하세요.
          type: 'warning', // success / info / warning / error
        });
      } else if (this.gridline.data.length < 2) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '결재선을 등록하세요.', // 결재선을 등록하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (this.popupParam.sysApprovalRequestId) { // 재상신의 경우
          let ordercnt = 1;
          this.$_.forEach(this.gridline.data, _item => {
            _item.approvalLineNo = ordercnt;
            ordercnt++;
          });
          chgdata = this.gridline.data.filter( x => {
            return x.approvalStatusName == ''
          });
          if (chgdata.length < 2) {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: '재상신 결재선을 등록하세요.', // 재상신 결재선을 등록하세요.
              type: 'warning', // success / info / warning / error
            });
            return false;
          } else {
            chgdata[0].approvalStatusCd = 'ASC0000000';
            chgdata[1].approvalStatusCd = 'ASC0000001';
          }
        }
        let saveData;
        let apprSignature = '';
        signaturePad = this.$refs.signaturePad
        if (signaturePad.saveSignature().isEmpty) {
          apprSignature = ''
        } else {
          apprSignature = signaturePad.saveSignature().data;
        }
        if (this.popupParam.sysApprovalRequestId) { // 재상신의 경우
          this.$_.extend(this.reqdata, {
            approvalConnId: this.popupParam.approvalConnId, // 결재업무 연결용 업무일련번호
            approvalTypeCd: this.apprtype.approvalTypeCd,  // 결재유형코드
            approvalStatusCd: 'ASC0000001',  // 결재상태 (결재중)
            approvalParam: JSON.stringify(this.popupParam.approvalParamValue),  // 결재상세화면 호출파라메터
          });
          saveData = chgdata.concat(this.gridline2.data);
          this.$http.url = this.saveApprRequestUrl + '/re';
          this.$http.type = 'PUT';
          this.$http.param = {
            approvalRequestModel: this.reqdata,
            approvalLineModel: saveData,
            sysApprovalRequestId: this.popupParam.sysApprovalRequestId,
            electronSignature: apprSignature,
          };
        } else {
          this.$_.extend(this.reqdata, {
            approvalConnId: this.popupParam.approvalConnId, // 결재업무 연결용 업무일련번호
            approvalTypeCd: this.apprtype.approvalTypeCd,  // 결재유형코드
            approvalStatusCd: 'ASC0000001',  // 결재상태 (결재중)
            approvalParam: JSON.stringify(this.popupParam.approvalParamValue),  // 결재상세화면 호출파라메터
          });
          let ordercnt = 0;
          // 상신자 제외 첫번째 결재자에 결재중상태로 변경
          this.$_.forEach(this.gridline.data, _item => {
            if (ordercnt == 1) {
              _item.approvalStatusCd = 'ASC0000001';
            }
            ordercnt++;
          })
          saveData = this.gridline.data.concat(this.gridline2.data);
          this.$http.url = this.saveApprRequestUrl;
          this.$http.type = 'POST';
          this.$http.param = {
            approvalRequestModel: this.reqdata,
            approvalLineModel: saveData,
            electronSignature: apprSignature,
          };
        }
        this.$http.request((_result) => {
          if (_result.data.sysApprovalRequestId !== 'DUPLICATE') {
            window.getApp.$emit('ALERT', {
              title: '안내', /* 안내 */
              message: '결재가 정상적으로 요청되었습니다.', /* 결재가 정상적으로 요청되었습니다. */
              type: 'success', // success / info / warning / error
            });
          } else {
            window.getApp.$emit('ALERT', {
              title: '안내', /* 안내 */
              message: '해당 업무는 이미 결재요청되었습니다.\r\n확인바랍니다.\r\n' + '기존결재요청자 : ' + _result.data.approvalExistModel.userName + ' ' + _result.data.approvalExistModel.spotName + '/' + _result.data.approvalExistModel.deptName, /* 해당 업무는 이미 결재요청되었습니다.\n확인바랍니다. */
              type: 'warning', // success / info / warning / error
            });
          }
          this.$emit('closePopup');
        });
      }
    },
    closeDialog() {
      this.dialogShow = false;
    },
    eraseSignature() {
      signaturePad.clearSignature();
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>
<style scoped>
.font-size-m-up {
  margin-top: 10px !important;
  font-size: 1.4em;
  font-weight: 700;
  display: inline-block;
  width: 100%;
  padding-bottom: 9px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  min-height: 24px;
}
.linehandley {
  float: left;
  margin-left: -10px;
  padding-top: 4px;
  padding-right: 10px;
  cursor: move;
}
.linehandlex {
  float: left;
  margin-left: -10px;
  padding-top: 4px;
  padding-right: 10px;
  opacity: 0.5;
}
.lineclose {
  float: right;
  padding-top: 0px;
  font-size: 20px;
  margin-right: -10px;
  cursor: pointer;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.3;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.linespan1 {
  min-width: 50px !important;
  text-align: left;
  font-weight: 600;
}
.linespan2 {
  min-width: 120px !important;
  text-align: left;
}
.linetext {
  display: inline-block;
}
.list-group-item {
  padding: 0.4rem  1.25rem !important;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 0.8rem;
}
.appr-line-detail-layer2 {
  margin: 10px !important;
  padding: 5px !important;
  border: 2px solid #7b1fa2;
  border-radius: 14px;
}
</style>
